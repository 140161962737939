/* eslint-disable */
import bootstrap from 'bootstrap';
import AOS from 'aos';
import Splide from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';
import 'masonry-layout';
//import 'jquery.mb.ytplayer';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgHash from 'lightgallery/plugins/hash';
import lgShare from 'lightgallery/plugins/share';

// Font Awesome Config
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPrint, faChevronLeft, faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faArrowRight, faArrowLeft, faPhone, faTags, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faStore, faComments, faEnvelopeOpen, faQuoteRight, faQuoteLeft, faFilter, faPencilAlt, faEdit, faCartPlus, faTruck, faMobileAlt, faLongArrowRight, faLongArrowLeft, faCalendarAlt, faExpandWide, faCompressWide, faArrowFromRight } from '@fortawesome/pro-regular-svg-icons';
import { faUser, faShoppingCart, faLocationArrow, faExclamationCircle, faCheck, faStar } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookSquare, faInstagram, faTwitterSquare, faFacebook, faTwitter, faGoogle, faYelp, faMicrosoft, faLinkedin, faPinterest, faYoutube, faVimeo, faSnapchat } from '@fortawesome/free-brands-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

// Icons with multiple styles
import { faChevronRight as farChevronRight, faMapMarker, faMap, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight as fasChevronRight, faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons';

//add icons to lib
library.add(faEnvelope, faMap, faMapMarker, faExclamationCircle, faLocationArrow, farChevronRight, fasChevronRight, faChevronLeft, faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faStar, faArrowRight, faArrowLeft, faPhone, faUser, faTags, faPrint, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faShoppingCart, faStore, faComments, faFacebookSquare, faTwitterSquare, faFacebook, faTwitter, faInstagram, faGoogle, faYelp, faBuilding, faEnvelopeOpen, faMicrosoft, faLinkedin, faPinterest, faYoutube, faVimeo, faSnapchat, faQuoteRight, faFilter, faPencilAlt, faEdit, faCartPlus, faExternalLinkAlt, faCheck, faTruck, faMobileAlt, faLongArrowRight, faCalendarAlt, faLongArrowLeft, faQuoteLeft, faExpandWide, faCompressWide, faArrowFromRight, fasArrowRight);

dom.watch();

export default {
    init() {
        // JavaScript to be fired on all pages
        // init aos
        AOS.init({
            disable: 'mobile',
            duration: 750,
            once: true,
        });
        // Smooth scrolling to ID
        // $('a[href*="#"]').on('click', function (e) {
        //   e.preventDefault();
        //   $('html, body').animate(
        //     {
        //       scrollTop: $($(this).attr('href')).offset().top,
        //     },
        //     500,
        //     'linear',
        //   );
        // });

        /****
        *************
        *************
          Nav Functions
        *************
        *************
        *****/
        // off canvas (not currently used)
        $('#desktopToggler').click(function () {
            $('.off-canvas-transform-menu').addClass('nav-flex');
            $('#siteVisibleArea').removeClass('site-visible-area-reverse');
            $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse');
            $('#siteVisibleArea').addClass('site-visible-area');
            $('#siteVisibleAreaInner').addClass('site-visible-area-inner');
            $('#canvasX').delay(500).fadeIn();
            $('#offCanvasMenu').delay(500).hide().animate({
                left: 'auto',
                opacity: 'show',
            }, 1000);
        });
        var mobileClicked = 0;
        $('#mobileToggler').click(function () {
            if (mobileClicked == 0) {
                $('#siteVisibleArea').removeClass('site-visible-area-reverse-mobile');
                $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse-mobile');
                $('#siteVisibleArea').addClass('site-visible-area-mobile');
                $('#siteVisibleAreaInner').addClass('site-visible-area-inner-mobile');
                $('#offCanvasMobile').delay(500).hide().animate({
                    left: 'auto',
                    opacity: 'show',
                }, 1000);
                mobileClicked = 1;
            } else if (mobileClicked == 1) {
                $('#siteVisibleArea').addClass('site-visible-area-reverse-mobile');
                $('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse-mobile');
                $('#siteVisibleArea').removeClass('site-visible-area-mobile');
                $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-mobile');
                $('#offCanvasMenu').hide();
                mobileClicked = 0;
            }
        });
        $('#canvasX').click(function () {
            $('.off-canvas-transform-menu').removeClass('nav-flex');
            $('#siteVisibleArea').addClass('site-visible-area-reverse');
            $('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse');
            $('#siteVisibleArea').removeClass('site-visible-area');
            $('#siteVisibleAreaInner').removeClass('site-visible-area-inner');
            $('#canvasX').hide();
            $('#offCanvasMenu').hide();
        });
        // dropdown on hover

        // Transparent nav scrolling function
        //caches a jQuery object containing the header element
        var transNav = $('#topLevelNav');
        var transNavHeight = transNav.outerHeight();
        var pageHeader = $('.page-header');
        var pageHeaderPadding = transNavHeight + 'px';
        // if trans nav, set initial page header top padding
        if ($('.trans-nav')[0]) {
            pageHeader.css('padding-top', pageHeaderPadding);
        }
        $(window).on('load scroll', function () {
            var transScroll = $(window).scrollTop();
            if (transScroll > 0.00) {
                transNav.removeClass('trans-top');
                transNav.addClass('trans-scrolling');
            } else {
                transNav.addClass('trans-top');
                transNav.removeClass('trans-scrolling');
            }
        });
        // Shrink on scroll nav function
        //caches a jQuery object containing the header element
        var shrinkNav = $('#topLevelNav');
        var shrinkNavbar = $('#site-navigation');
        $(window).on('load scroll', function () {
            var shrinkScroll = $(window).scrollTop();
            if (shrinkScroll > 0.00) {
                shrinkNav.addClass('nav-shrink');
                shrinkNavbar.removeClass('py-3');
                shrinkNavbar.addClass('py-2');
                return false;
            } else {
                shrinkNav.removeClass('nav-shrink');
                shrinkNavbar.addClass('py-3');
                shrinkNavbar.removeClass('py-2');
                return false;
            }
        });
        // Hide topbar on scroll
        //caches a jQuery object containing the header element
        var hideTopbar = $('.topbar-hide');
        var topbarHidden = false;
        if (hideTopbar) {
            if (topbarHidden != true) {
                $(window).scroll(function () {
                    var topbarOffset = $(window).scrollTop();
                    if (topbarOffset > 50.00) {
                        hideTopbar.css('display', 'none');
                        topbarHidden = true;
                        return false;
                    }
                    else if (topbarOffset < 10.00) {
                        hideTopbar.css('display', 'block');
                        return false;
                    }
                });
            }
        }

        //onclick
        $('.primary-btn').click(function() {
            // jQuery('#prontoGform').html(<?php //echo json_encode(do_shortcode('[gravityforms id="5" title="false" ajax="true" tabindex="49"]')); ?>);
            // jQuery('#gform_wrapper_5').show();
            jQuery("#signup").modal('show');
            console.log('test');
        });

        $(document).on('gform_confirmation_loaded', function(){
          // code to run upon successful form submission
          $('p#hideOnSubmit').hide();
        });

        /****
        *************
        *************
          Add ADA Compliance to h1, h2, h3 etc div tags
        *************
        *************
        *****/
        $('.h1').each(function(){
            var h1Title = $(this);
            h1Title.attr('role', 'heading');
            h1Title.attr('aria-level', '1');
        });
        $('.h2').each(function(){
            var h2Title = $(this);
            h2Title.attr('role', 'heading');
            h2Title.attr('aria-level', '1');
        });
        $('.h3').each(function(){
            var h3Title = $(this);
            h3Title.attr('role', 'heading');
            h3Title.attr('aria-level', '1');
        });
        $('.h4').each(function(){
            var h4Title = $(this);
            h4Title.attr('role', 'heading');
            h4Title.attr('aria-level', '1');
        });
        $('.h5').each(function(){
            var h5Title = $(this);
            h5Title.attr('role', 'heading');
            h5Title.attr('aria-level', '1');
        });
        /****
        *************
        *************
          Sliders
        *************
        *************
        *****/

        /*
         ** Logo Banner Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var logoSliders = document.getElementsByClassName('rpb-logo-slider');
        for (var logoCount = 0; logoCount < logoSliders.length; logoCount++) {
            new Splide(logoSliders[logoCount]).mount();
        }

        /*
         ** Hero Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var heroSliders = document.getElementsByClassName('rpb-hero-slider');
        for (var heroCount = 0; heroCount < heroSliders.length; heroCount++) {
            new Splide(heroSliders[heroCount]).mount();
        }

        /*
         ** Bootstrap Cards Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var cardSliders = document.getElementsByClassName('bootstrap-cards-slider');
        for (var cardSliderCount = 0; cardSliderCount < cardSliders.length; cardSliderCount++) { console.log('foundit');
          // mount a new slider with the Intersection observer
          var cardSlider = new Splide(cardSliders[cardSliderCount]).mount({ Intersection });
          // on intersection, set the data-src of the slider img to the src
          cardSlider.on( 'intersection:in', function ( entry ) {
              $(entry.target).find('.lazy').each(function() {
                if ( entry.isIntersecting ) {
                  var imgAttr = $(this).attr('data-src');
                  $(this).attr('src',imgAttr);
                  var bgImage = $(this).attr('data-bgimage');
                  if (bgImage) {
                    this.style.backgroundImage = bgImage;
                  }
                  var bgSize = $(this).attr('data-bgsize');
                  if (bgSize) {
                    this.style.backgroundSize = bgSize;
                  }
                  var bgPosition = $(this).attr('data-bgposition');
                  if (bgPosition) {
                    this.style.backgroundPosition = bgPosition;
                  }
                  var bgRepeat = $(this).attr('data-bgrepeat');
                  if (bgRepeat) {
                    this.style.backgroundRepeat = bgRepeat;
                  }
                }
              });
          });
        }

        /*
         ** Image Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var imageSliders = document.getElementsByClassName('rpb-image-slider');
        for (var ii = 0; ii < imageSliders.length; ii++) {
            // assign slider elements to variables
            var sliderID = imageSliders[ii].id;
            var sliderElement = document.getElementById(sliderID);
            var sliderParentID = sliderElement.parentElement.id;
            var sliderParentElement = document.querySelector('#' + sliderParentID);
            var thumbnailSliderElement = sliderParentElement.querySelector('.rpb-image-slider-thumbnails');
            // assign the main slider a variable
            var mainSlider = new Splide(sliderElement);

            // if thumbnail gallery
            if (thumbnailSliderElement) {
                // assign the thumbnail slider a variable
                var thumbnailSlider = new Splide(thumbnailSliderElement, {
                    rewind: true,
                    fixedWidth: 104,
                    fixedHeight: 58,
                    isNavigation: true,
                    arrows: false,
                    gap: 10,
                    focus: 'center',
                    pagination: false,
                    cover: true,
                    dragMinThreshold: {
                        mouse: 4,
                        touch: 10,
                    },
                    breakpoints: {
                        640: {
                            fixedWidth: 66,
                            fixedHeight: 38,
                        },
                    },
                });
                // mount the sliders
                mainSlider.sync(thumbnailSlider);
                mainSlider.mount();
                thumbnailSlider.mount();
            }

            // slider without thumbnails
            else {
                mainSlider.mount();
            }
        }

        /*
         ** Customer Reviews Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var reviewSliders = document.getElementsByClassName('customer-reviews-slider');
        for (var reviewSlidersCount = 0; reviewSlidersCount < reviewSliders.length; reviewSlidersCount++) {
            new Splide(reviewSliders[reviewSlidersCount]).mount();
        }

        /*
         ** Blog Feed Slider
         */

        // Loop through each instance of a slider and initialize based on the matching class
        var blogFeeds = document.getElementsByClassName('blog-feed-slider');
        for (var blogFeedsCount = 0; blogFeedsCount < blogFeeds.length; blogFeedsCount++) {
            new Splide(blogFeeds[blogFeedsCount]).mount();
        }

        /****
        *************
        *************
          Additional Page Builder Functions
        *************
        *************
        *****/

        // Photo Gallery Lightgallery
        var galleryLightbox = document.getElementsByClassName('gallery-lightbox');
        for (var galleryCount = 0; galleryCount < galleryLightbox.length; galleryCount++) {
            var galleryID = galleryLightbox[galleryCount].id;
            var galleryElement = document.getElementById(galleryID);
            var gallerySelector = $('.photo-gallery-photo');
            var galleryAlt = $(gallerySelector).attr('data-alt');
            lightGallery(galleryElement, {
                plugins: [lgZoom, lgThumbnail, lgHash, lgShare],
                thumbnail: false,
                selector: '.photo-gallery-photo',
                alt: galleryAlt,
            });
            console.log(galleryAlt);
        }

        /****
        *************
        *************
          WooCommerce
        *************
        *************
        *****/
        // show mobile filter on button click
        $('#shopFilter').click(function () {
            $('.basebuild-shop-filter-in').slideToggle();
        });
        // review form on button click
        $('#addRevewButton').click(function () {
            $('#commentform').slideToggle();
        });
        if (!String.prototype.getDecimals) {
            String.prototype.getDecimals = function () {
                var num = this,
                    match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
                if (!match) {
                    return 0;
                }
                return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
            }
        }
        // Quantity "plus" and "minus" buttons
        $(document.body).on('click', '.plus, .minus', function () {
            var $qty = $(this).closest('.quantity').find('.qty'),
                currentVal = parseFloat($qty.val()),
                max = parseFloat($qty.attr('max')),
                min = parseFloat($qty.attr('min')),
                step = $qty.attr('step');
            // Format values
            if (!currentVal || currentVal === '' || currentVal === 'NaN') currentVal = 0;
            if (max === '' || max === 'NaN') max = '';
            if (min === '' || min === 'NaN') min = 0;
            if (step === 'any' || step === '' || step === undefined || parseFloat(step) === 'NaN') step = 1;
            // Change the value
            if ($(this).is('.plus')) {
                if (max && (currentVal >= max)) {
                    $qty.val(max);
                } else {
                    $qty.val((currentVal + parseFloat(step)).toFixed(step.getDecimals()));
                }
            } else {
                if (min && (currentVal <= min)) {
                    $qty.val(min);
                } else if (currentVal > 0) {
                    $qty.val((currentVal - parseFloat(step)).toFixed(step.getDecimals()));
                }
            }
            // Trigger change event
            $qty.trigger('change');
        });
      var collapseElementList = [].slice.call(document.getElementsByClassName('.mso-collapse'));
      var collapseList = collapseElementList.map(function (collapseEl) {
          return new bootstrap.Collapse(collapseEl);
      });
      $('.collapse').on("show.bs.collapse", function(){
          $(this).closest('.mso-sidebar-button').addClass('active');
          $(this).closest('.mso-sidebar-button').addClass('py-3');
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').hide();
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').hide();
      });
      $('.collapse').on("hide.bs.collapse", function(){
          $(this).closest('.mso-sidebar-button').removeClass('active');
          $(this).closest('.mso-sidebar-button').removeClass('py-3');
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').show();
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').show();
      });
      var collapseElementList = [].slice.call(document.getElementsByClassName('.mso-collapse'));
      var collapseList = collapseElementList.map(function (collapseEl) {
          return new bootstrap.Collapse(collapseEl);
      });
      $('.collapse').on('show.bs.collapse', function(){
          $(this).closest('.mso-sidebar-button').addClass('active');
          $(this).closest('.mso-sidebar-button').addClass('py-3');
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').hide();
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').hide();
      });
      $('.collapse').on('hide.bs.collapse', function(){
          $(this).closest('.mso-sidebar-button').removeClass('active');
          $(this).closest('.mso-sidebar-button').removeClass('py-3');
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').show();
          $(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').show();
      });
      // Fullscreen Map Expand
      $('#msoFullscreen').click(function() {
        $('#msoMap').toggleClass('fullscreen');
        $('.msofullscreen').toggleClass('d-none');
        $('.msocompress').toggleClass('d-block');
        $('.store-locator-sidebar').toggleClass('rounded-0');
        $('.store-locator-fullscreen-in').toggleClass('rounded-bottom-0');
        $('body').toggleClass('overflow-hidden');
      });

      // Show filters (desktop)
      $('#showFilters').click(function() {
        $('#msoFilterPanel').css('width','200%');
        $('#msoFilterPanel').css('opacity','1');
        $('#msoFilterPanel').css('visibility','visible');
        $('.mso-filter-back').css('visibility','visible');
      });
      // Hide filters (desktop)
      $('#msoFilterBack').click(function() {
        $('#msoFilterPanel').css('width','0');
        $('#msoFilterPanel').css('opacity','0');
        $('#msoFilterPanel').css('visibility','hidden');
        $('.mso-filter-back').css('visibility','hidden');
      });

      // Show filters (mobile)
      $('#showFiltersMobile').click(function() {
        $('#msoFilterPanel').css('opacity','1');
        $('#msoFilterPanel').css('visibility','visible');
        $('.mso-filter-back').css('visibility','visible');
      });
      // Hide filters (mobile)
      $('#msoFilterBackMobile').click(function() {
        $('#msoFilterPanel').css('width','0');
        $('#msoFilterPanel').css('opacity','0');
        $('#msoFilterPanel').css('visibility','hidden');
        $('.mso-filter-back').css('visibility','hidden');
      });

      // Apply filters (hide filter)
      // Desktop
      $('#submitMsoFilters').click(function() {
        $('#msoFilterPanel').css('width','0');
        $('#msoFilterPanel').css('opacity','0');
        $('#msoFilterPanel').css('visibility','hidden');
        $('.mso-filter-back').css('visibility','hidden');
      });
      $('#submitMsoFiltersMobile').click(function() {
        $('#msoFilterPanel').css('opacity','0');
        $('#msoFilterPanel').css('visibility','hidden');
        $('.mso-filter-back').css('visibility','hidden');
      });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
